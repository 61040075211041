@import './variables';
@import 'bootstrap/scss/bootstrap';

// html {
//     user-select: none;
// }

body {
    overscroll-behavior: none;
}
textarea {
    resize: none;
}
// .invalid-feedback {
//     position: absolute;
//     z-index: 1;
// }
.form-group {
    margin-bottom: 1rem;
}
.form-label {
    line-height: 1.25;
}
.invalid-feedback {
    line-height: 1.25;
}
.form-control {
    box-shadow: inset 0 0.0625rem 0.125rem rgba(#000, .075);
}
.card {
    box-shadow: 0 0.5rem 1rem rgba(#000, .15);
}
.modal-content {
    box-shadow: 0 1rem 2rem rgba(#000, .3);
}
.sr-only {
    display: none;
}
.close {
    padding: 0 0.5rem;
    margin: 0;
    background: none;
    appearance: none;
    font-size: 1.5rem;
    color: #999;
    border: none;
}
.modal-footer {
    padding: 0.5rem;
    justify-content: flex-start;
}
.toast-header {
    padding: 0 0.75rem;
    padding-right: 0.25rem;
}

.badge-primary {
    background: $primary;
}
.badge-light {
    background: $light;
    color: $dark;
}

:not(.btn-check) + .btn:not(.btn-link):hover, .btn:not(.btn-link):first-child:hover, .btn-check:checked + .btn, :not(.btn-check) + .btn:not(.btn-link):active, .btn:not(.btn-link):first-child:active, .btn:not(.btn-link).active, .btn:not(.btn-link).show {
    color: #fff;
}

.category-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    flex-direction: row;
    // border-top: 1px solid #e5e5e5;
    // border-left: 1px solid #e5e5e5;
    border-radius: 0.25rem;
    overflow: hidden;
    // margin-top: 0.5rem;
    // margin-bottom: 1.5rem;
}
.category-list > li {
    margin: 0;
    display: inline-block;
    flex: 1;
    // border-bottom: 1px solid #e5e5e5;
    // border-right: 1px solid #e5e5e5;
    &:last-child {
        border-bottom-right-radius: 0.25rem;
    }
}
.category-list > li > button {
    border-radius: 0;
    padding: 1rem;
}
h1 {
    font-size: 2.5rem;
}
h2 {
    font-size: 2rem;
}
h3 {
    font-size: 1.5rem;
}
.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    color: #fff;
}
.card-header-tabs {
    margin-right: inherit;
    margin-bottom: inherit;
    margin-left: inherit;
    border-bottom: inherit;
}