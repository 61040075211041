.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}
.header {
    padding: 0;
    background: #fff;
    display: flex;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    width: 100%;
    /* z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10; */
}
.content {
    flex: 1;
    background-color: #f2f2f2;
    /* padding: 3.5rem 0; */
}
.headerBrand {
    padding: 0.5rem;
    line-height: 0;
    color: inherit;
    text-decoration: none;
}
.headerBrand:hover {
    color: inherit;
}
.brandText {
    height: 2.5rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
    padding: 0 0.25rem;
}
.headerLogo {
    height: 2.5rem;
    width: auto;
}
.headerSpacer {
    flex: 1;
}
.headerNav {
    padding: 0;
    position: relative;
    list-style: none;
    margin: 0;
}
.headerNavItem {
    padding: 0.5rem;
}
.menuToggle {
    background: none !important;
    border: none !important;
    padding: 0.5rem;
    color: inherit !important;
}
.menuToggle:after {
    display: none !important;
}
.menu {
    border: none;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}
.navbar {
    background: #fff;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 10;
}
.nav {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    width: 100%;
    color: #808080;
}
.navItem {
    padding: 1rem;
    padding-top: 0.875rem;
    padding-bottom: 1.125rem;
    text-align: center;
    color: inherit;
}
.navItem:hover {
    color: inherit;
}
.navIcon {
    height: 1.5rem;
    width: 1.5rem;
}
.navLabel {
    font-size: 0.75rem;
    margin-top: 0.25rem;
    margin-bottom: -0.25rem;
}
.infoLogo {
    max-height: 5rem;
    max-width: 15rem;
    margin-bottom: 1rem;
}